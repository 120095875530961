<template>
  <section>
    <div>
      <span
        v-if="isActive
        ||
        isDataTableField"
        class="setting-icon"
        @click="openSettings"
      >
        <i class="el-icon-s-tools" />
      </span>
      <el-link style="color: #409eff;"
        v-if="data && data.action_button_type == 'HYPERLINK'"
        :style="{ color: '#409EFF' }"
      >
        {{ data.label }}
      </el-link>
      <el-button
        :style="getStyle"
        :round="isRound"
        v-else
      >
        {{ data.label }}
      </el-button>
      <i
        class="el-icon-copy-document " @click="copyDocument" ></i>
    </div>
  </section>
</template>

<script>
import templateBuilderHelper from "@/mixins/templateBuilderHelper";
import { mapGetters } from "vuex";

export default {
  name: "ActionButtonView",
  components: {},
  props: ["data", "isActive", "selfTemplateId", "isDataTableField"],
  mixins: [templateBuilderHelper],
  computed: {
    ...mapGetters("companyTemplates", ["getSingleCompanyTemplate"]),
    getButtonColor() {
      if (this.data?.styles?.label_color) {
        return this.data.styles.label_color;
      }
      return "white";
    },
    getBackgroundColor() {
      if (this.data?.styles?.input_background) {
        return this.data.styles.input_background;
      }
      return "#285ED3";
    },
    getStyle() {
      let style = `color:${this.getButtonColor};`;
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
        let bold =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 1 || this.data.styles.font.style == 3)
            ? "bold"
            : "";
        let italic =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 2 || this.data.styles.font.style == 3)
            ? "italic"
            : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
        borderStyle += ";";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }
        if (this.data?.styles?.font?.name) {
          borderStyle += "font-family:" + this.data.styles.font.name + ";";
        }
      }
      if (this.data?.action_button_type != "HYPERLINK") {
        style += `background-color: ${this.getBackgroundColor} !important;`;
      }
      style += borderStyle;
      return style;
    },
    getElementStyle() {
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
        let font_size =
          this.data.styles &&
          this.data.styles.font &&
          this.data.styles.font.font_size
            ? this.data.styles.font.font_size
            : "";
        let bold =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 1 || this.data.styles.font.style == 3)
            ? "bold"
            : "";
        let italic =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 2 || this.data.styles.font.style == 3)
            ? "italic"
            : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
        borderStyle += ";";
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
        borderStyle += ";";
        borderStyle += "font-size:" + font_size + "px;";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
      }
      return borderStyle;
    },
    isRound() {
      if (this.data?.styles?.botton_type == "Rounded") {
        return true;
      }
      return false;
    }
  },
  data() {
    return {
      validations: [],
      selectedTemplateFields: [],
      tableData: []
    };
  },
  mounted() {
  },
  methods: {
    copyDocument() {
    this.$emit("copy-document", this.data.key);
  },
    action() {
      if (this.data.action_button_type == "TEMPLATE") {
        console.log("test");
      } else {
        if (this.data.action_button_target_link) {
          switch (this.data.action_button_target_location) {
            case "SELF":
              window.location.href = this.data.action_button_target_link;
              break;
            case "NEW_TAB":
              window.open(this.data.action_button_target_link, "_blank");
              break;
            case "NEW_WINDOW":
              window.open(this.data.action_button_target_link, "_blank");
              break;
            default:
              window.location.href = this.data.action_button_target_link;
              break;
          }
        } else {
          this.$message.info("No Hyperlink to open");
        }
      }
    },
    async fetchTemplate() {
      try {
        await this.$store.dispatch(
          "companyTemplates/fetchSingleCompanyTemplate",
          this.data.action_button_repeating_template
        );
        if (
          this.getSingleCompanyTemplate &&
          this.getSingleCompanyTemplate.sections &&
          this.getSingleCompanyTemplate.sections.length
        ) {
          this.selectedTemplateFields = this.getSingleCompanyTemplate.sections[0].fields;
          this.data.max_height = 400;

          if (this.data.height < 100) {
            this.data.height = 100;
          }
          if (
            this.data.width <
            (this.selectedTemplateFields.length + 1) * 100
          ) {
            this.data.width = (this.selectedTemplateFields.length + 1) * 100;
          }
          this.data.max_width = (this.selectedTemplateFields.length + 1) * 300;
        }
      } catch (error) {
        console.log("fetchTemplate",error);
      }
    },
    openSettings() {
      this.$emit("settings");
    }
  }
};
</script>

<style lang="scss">
.heading {
  margin: 3px 0px 3px 0px !important;
  line-height: 20px;
}



</style>
